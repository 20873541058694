<!--
--------------------------------------------------------------------------------
<copyright file="ShiftFilter.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <details>
    <summary>
      <p class="list">{{ $t('widget_info_text.shift_filter') }}</p>
    </summary>
    <img src="images/info/shiftFilter.jpg" class="list-item" />

    <p class="list-item text">{{ $t('widget_info_text.shift_txt') }}</p>
    <ul class="list">
      <li class="list-item">{{ $t('widget_info_text.shift_txt1') }}</li>
      <li class="list-item">{{ $t('widget_info_text.shift_txt2') }}</li>
      <li class="list-item">{{ $t('widget_info_text.shift_txt3') }}</li>
    </ul>
  </details>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class ShiftFilterInfo extends Vue {}
</script>
