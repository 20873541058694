<!--
--------------------------------------------------------------------------------
<copyright file="LaserProductivity.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.outer_arc') }}</summary>
      <p class="text">{{ $t('widget_info_text.prod_outer_arc') }}:</p>
      <ul class="list">
        <li class="list-item work" style="text-transform: capitalize">
          {{ $t('widget_info_text.working_time') }}
        </li>
        <li class="list-item error">{{ $t('widget_info_text.unproductive_time') }}</li>
      </ul>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <p class="text tip-bkg">{{ $t('widget_info_text.tooltip_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.inner_arc') }}</summary>
      <p class="text">{{ $t('widget_info_text.prod_inner_arc') }}</p>
      <ul class="list">
        <li class="list-item" style="text-transform: capitalize">
          {{ $t('widget_info_text.working_time') }}
        </li>
        <li class="list-item">{{ $t('widget_info_text.unproductive_time') }}</li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.left_value') }}</summary>
      <p class="text">{{ $t('widget_info_text.prod_left_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.right_value') }}</summary>
      <p class="text">{{ $t('widget_info_text.prod_right_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.icons') }}</summary>
      <time-filter />
      <shift-filter />
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
import TimeFilterInfo from '@/components/Charts/widgetInfo/TimeFilter.vue';
import ShiftFilterInfo from '@/components/Charts/widgetInfo/ShiftFilter.vue';

@Component({
  components: {
    'time-filter': TimeFilterInfo,
    'shift-filter': ShiftFilterInfo,
  },
})
export default class LaserProductivityWidgetInfo extends Vue {}
</script>
