<!--
--------------------------------------------------------------------------------
<copyright file="TimeFilter.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <details>
    <summary>
      <p class="list">{{ $t('widget_info_text.time_filter') }}</p>
    </summary>
    <img src="images/info/timeFilter.jpg" class="list-item" />
    <p class="list-item text">{{ $t('widget_info_text.time_filter_txt') }}</p>
    <ul class="list">
      <li class="list-item">
        <img src="images/info/dayFilter.jpg" /> {{ $t('widget_info_text.time_filter_day') }}
      </li>
      <li class="list-item">
        <img src="images/info/weekFilter.jpg" /> {{ $t('widget_info_text.time_filter_week') }}
      </li>
      <li class="list-item">
        <img src="images/info/monthFilter.jpg" /> {{ $t('widget_info_text.time_filter_month') }}
      </li>
      <li class="list-item">
        <img src="images/info/yearFilter.jpg" /> {{ $t('widget_info_text.time_filter_year') }}
      </li>
    </ul>
    <p>
      <span class="demi-bold list">{{ $t('widget_info_text.current_period') }}</span>
      <img src="images/info/currentTimePeriod.jpg" class="list" /><br />
      <span class="demi-bold list"
        >{{ $t('widget_info_text.previous_period') }}<br />
        <img src="images/info/previousTimePeriod.jpg" class="list"
      /></span>
    </p>
    <p class="list">
      <span class="demi-bold list-item">t<sub>a</sub></span>
      {{ $t('widget_info_text.current_time') }}
    </p>
    <p class="list">
      <span class="demi-bold list-item">t<sub>1</sub></span>
      {{ $t('widget_info_text.selected_period') }}
    </p>
    <p class="list">
      <span class="demi-bold list-item">t<sub>2</sub></span>
      {{ $t('widget_info_text.previous_period') }}
    </p>
  </details>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class TimeFilterInfo extends Vue {}
</script>
